import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { useStaticQuery, graphql } from 'gatsby';

import Header from 'src/components/Header';
import Section from 'src/components/Section';
import SectionContainer from 'src/components/SectionContainer';
import BackgroundImage from 'src/components/BackgroundImage';

const MouseScollIcon = styled.div`
  width: 25px;
  height: 45px;
  border: 2px solid white;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  text-align: center;
`;

const MouseScrollIconWheel = styled.span`
  height: 6px;
  margin: 2px auto 0;
  display: block;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  animation: 1.6s ease infinite wheel-up-down;
  @keyframes wheel-up-down {
    0% {
      margin-top: 2px;
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      margin-top: 20px;
      opacity: 0;
    }
  }
`;

const Background = () => {
  const data = useStaticQuery(graphql`
    query {
      intro: file(relativePath: { eq: "intro-2021-11-24.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      fluid={data.intro.childImageSharp.fluid}
      position="bottom center"
      withDarkGradient
    ></BackgroundImage>
  );
};

const Hero = () => (
  <Flex flexDirection="column" height="100vh">
    <Header />
    <Section name="home">
      <Background />
      <SectionContainer style={{ height: '100vh' }}>
        <Box
          alignSelf="center"
          sx={{
            position: 'absolute',
            bottom: '20px',
            margin: '0 auto',
          }}
        >
          <Link to="aboutUs" smooth>
            <MouseScollIcon>
              <MouseScrollIconWheel />
            </MouseScollIcon>
          </Link>
        </Box>
      </SectionContainer>
    </Section>
  </Flex>
);

export default Hero;
