import React from 'react';
import { Heading, Box, Text } from 'rebass/styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import GoogleMapReact from 'google-map-react';
import { Image } from 'rebass/styled-components';

import Section from 'src/components/Section';
import SectionContainer from 'src/components/SectionContainer';
import LogoMobile from 'src/assets/images/logos/logo-mobile.svg';

const Background = () => <div></div>;

const Pin = ({ children }) => <div>{children}</div>;

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const Contact = () => {
  const mapProps = {
    center: {
      lat: 38.019925,
      lng: 23.804647,
    },
    zoom: 15,
  };

  return (
    <Section name="contact" Background={Background}>
      <SectionContainer>
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          mt={[1, 2]}
          mb={[4, 5]}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="contact.title" />
        </Heading>

        <Text>
          <FormattedMessage
            id="contact.address"
            values={{
              br: <br />,
            }}
          />
          <br />
          <br />
          <FormattedMessage id="contact.tel" />: <a href="tel:+30 210 68 90 200">+30 210 68 90 200</a>
          <br />
          <FormattedMessage id="contact.mob" />: <a href="tel:+30 6944 34 77 47">+30 6944 34 77 47</a>,{' '}
          <a href="tel:+30 6944 26 80 85">+30 6944 26 80 85</a>
          <br />
          Fax: <a href="fax:+30 210 68 51 630">+30 210 68 51 630</a>
          <br />
          Email: <a href="mailto:info@paisiosconstructions.gr">info@paisiosconstructions.gr</a>
          <br />
        </Text>
      </SectionContainer>

      <Box sx={{ width: '100%', height: '400px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAohpwqdGRsJSuES-pWwl1yKNtC2wvs-ZA' }}
          defaultCenter={mapProps.center}
          defaultZoom={mapProps.zoom}
        >
          <Pin lat={38.019925} lng={23.804647}>
            <Image
              src={LogoMobile}
              width="40px"
              height="40px"
              alt="Paisios Constructions"
              sx={{
                backgroundColor: 'white',
                borderRadius: '5px',
                padding: '5px',
                boxShadow: '0 0 10px rgba(0,0,0,0.7)',
              }}
            />
          </Pin>
        </GoogleMapReact>
      </Box>
    </Section>
  );
};

export default Contact;
