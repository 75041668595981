import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';

const BackgroundImage = styled(Img)`
  position: absolute !important;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  & img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    font-family: "object-fit: ${props => props.fit || 'cover'} !important; object-position: ${props =>
  props.position || '50% 50%'} !important;"
  }
  ${props =>
    props.withDarkOverlay &&
    `&:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 0;
  }`};
  ${props =>
    props.withDarkGradient &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 20%);
      opacity: 0.3;
    },
    `};
`;

export default BackgroundImage;
