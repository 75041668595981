import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'src/components/SEO';
import Layout from 'src/components/Layout';
import Hero from 'src/sections/Hero';
import AboutUs from 'src/sections/AboutUs';
import Services from 'src/sections/Services';
import Showcase from 'src/sections/ShowCase';
import Contact from 'src/sections/Contact';
import Footer from 'src/sections/Footer';

const Home = () => {
  return (
    <Layout>
      <Helmet />
      <Hero />
      <AboutUs />
      <Services />
      <Showcase />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default Home;
