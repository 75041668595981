import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Flex, Text, Heading } from 'rebass/styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Section from 'src/components/Section';
import SectionContainer from 'src/components/SectionContainer';
import BackgroundImage from 'src/components/BackgroundImage';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      image15: file(relativePath: { eq: "decoration/15.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Section name="services">
      <SectionContainer>
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          mt={[1, 2]}
          mb={[4, 5]}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="services.title" />
        </Heading>

        <Text mx={2} mb={[3, 5]} sx={{ width: '100%' }}>
          <FormattedMessage id="services.content" />
        </Text>

        <Text>
          <ul>
            <li>
              <FormattedMessage id="services.service0" />
            </li>
            <li>
              <FormattedMessage id="services.service1" />
            </li>
            <li>
              <FormattedMessage id="services.service2" />
            </li>
            <li>
              <FormattedMessage id="services.service3" />
            </li>
            <li>
              <FormattedMessage id="services.service4" />
            </li>
            <li>
              <FormattedMessage id="services.service5" />
            </li>
            <li>
              <FormattedMessage id="services.service6" />
            </li>
            <li>
              <FormattedMessage id="services.service7" />
            </li>
          </ul>
        </Text>
      </SectionContainer>

      <Flex flexWrap="wrap">
        <Flex
          order={1}
          py={5}
          px={[4, 5]}
          width={[1, 1, 1 / 2]}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg="grey1"
        >
          <Heading as="h4" color="primary" fontSize={[3, 4]} mt={[1, 2]} mb={[4, 5]}>
            <FormattedMessage id="characteristics.title" />
          </Heading>

          <Text sx={{ width: '100%' }}>
            <ul>
              <li>
                <FormattedMessage id="characteristics.characteristic0" />
              </li>
              <li>
                <FormattedMessage id="characteristics.characteristic1" />
              </li>
              <li>
                <FormattedMessage id="characteristics.characteristic2" />
              </li>
              <li>
                <FormattedMessage id="characteristics.characteristic3" />
              </li>
              <li>
                <FormattedMessage id="characteristics.characteristic4" />
              </li>
            </ul>
          </Text>
        </Flex>
        <Flex
          order={2}
          py={5}
          px={[4, 5]}
          fontSize={3}
          width={[1, 1, 1 / 2]}
          color="white"
          bg="primary"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <BackgroundImage fluid={data.image15.childImageSharp.fluid}></BackgroundImage>
        </Flex>
      </Flex>
    </Section>
  );
};

export default Services;
