import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading, Flex, Text } from 'rebass/styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import BackgroundImage from 'src/components/BackgroundImage';
import Section from 'src/components/Section';
import SectionContainer from 'src/components/SectionContainer';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      mastoras: file(relativePath: { eq: "decoration/19.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      construction: file(relativePath: { eq: "decoration/9.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Section name="aboutUs">
      <SectionContainer>
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          mt={[1, 2]}
          mb={[4, 5]}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="aboutUs.title" />
        </Heading>

        <Text mx={2} sx={{ width: '100%' }}>
          <FormattedMessage id="aboutUs.text" />
        </Text>
      </SectionContainer>

      <Flex flexWrap="wrap">
        <Flex
          order={1}
          py={5}
          px={[4, 5]}
          width={[1, 1, 1 / 2]}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg="grey1"
        >
          <Heading as="h4" color="primary" fontSize={[3, 4]} mt={[1, 2]} mb={[4, 5]}>
            <FormattedMessage id="aboutUs.visionAndPrinciples.title" />
          </Heading>
          <Text sx={{ width: '100%' }}>
            <FormattedMessage id="aboutUs.visionAndPrinciples.content" />
            <br />
            <br />
            <ul>
              <li>
                <FormattedMessage id="aboutUs.visionAndPrinciples.principle0" />
              </li>
              <li>
                <FormattedMessage id="aboutUs.visionAndPrinciples.principle1" />
              </li>
              <li>
                <FormattedMessage id="aboutUs.visionAndPrinciples.principle2" />
              </li>
              <li>
                <FormattedMessage id="aboutUs.visionAndPrinciples.principle3" />
              </li>
              <li>
                <FormattedMessage id="aboutUs.visionAndPrinciples.principle4" />
              </li>
              <li>
                <FormattedMessage id="aboutUs.visionAndPrinciples.principle5" />
              </li>
            </ul>
          </Text>
        </Flex>
        <Flex
          order={2}
          width={[1, 1, 1 / 2]}
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <BackgroundImage fluid={data.mastoras.childImageSharp.fluid} withDarkOverlay></BackgroundImage>
          <Text
            p={5}
            color="white"
            fontSize={3}
            fontStyle="italic"
            sx={{
              zIndex: 1,
              width: '100%',
            }}
          >
            &ldquo;
            <FormattedMessage id="aboutUs.quote1" />
            &rdquo;
          </Text>
        </Flex>
      </Flex>

      <SectionContainer>
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          mt={[1, 2]}
          mb={[4, 5]}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="aboutUs.sustainable.title" />
        </Heading>

        <Text mx={2} sx={{ width: '100%' }}>
          <FormattedMessage
            id="aboutUs.sustainable.content"
            values={{
              br: <br />,
            }}
          />
        </Text>
      </SectionContainer>

      <Flex flexWrap="wrap">
        <Flex
          p={5}
          fontSize={3}
          width={1}
          color="white"
          bg="primary"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <BackgroundImage fluid={data.construction.childImageSharp.fluid} withDarkOverlay></BackgroundImage>
          <SectionContainer>
            <Text
              fontStyle="italic"
              sx={{
                zIndex: 1,
                width: '100%',
              }}
            >
              &ldquo;
              <FormattedMessage id="aboutUs.quote2" />
              &rdquo;
            </Text>
          </SectionContainer>
        </Flex>
      </Flex>
    </Section>
  );
};

export default AboutUs;
