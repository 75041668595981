import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Heading, Flex } from 'rebass/styled-components';
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';

import Section from 'src/components/Section';
import SectionContainer from 'src/components/SectionContainer';
import ShowCaseCard from 'src/components/ShowCaseCard';

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const StyledLink = styled(Link)`
  margin: 3em auto 0;
  text-align: center;
  color: ${props => props.theme.colors.grey3};
  text-decoration: underline;
`;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const Showcase = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      erythreaB: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/erythrea-b" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      chalandriB: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/chalandri-b" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      erythreaA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/erythrea-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      vrilissiaG: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/vrilissia-g" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
      chalandriA: allFile(
        sort: { order: ASC, fields: [base] }
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "properties/chalandri-a" } }
      ) {
        edges {
          node {
            base
            ...fluidImage
          }
        }
      }
    }
  `);

  const erythreaAImages = data.erythreaA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.erythreaA' }),
    };
  });

  const vrilissiaGImages = data.vrilissiaG.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.vrilissiaG' }),
    };
  });

  const chalandriAImages = data.chalandriA.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.chalandriA' }),
    };
  });

  const chalandriBImages = data.chalandriB.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.chalandriB' }),
    };
  });

  const erythreaBImages = data.erythreaB.edges.map(image => {
    return {
      src: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fluid.src,
      caption: intl.formatMessage({ id: 'projects.erythreaB' }),
    };
  });

  return (
    <Section name="showcase">
      <SectionContainer>
        <Heading
          as="h2"
          color="primary"
          fontSize={[4, 5]}
          mt={[1, 2]}
          mb={[4, 5]}
          textAlign="center"
          style={centerHorizontally}
        >
          <FormattedMessage id="showcase.title" />
        </Heading>

        <Flex flexWrap="wrap" sx={{ width: '100%' }}>
          <ShowCaseCard
            cardImage={data.erythreaB.edges[0].node.childImageSharp.fluid}
            projectImages={erythreaBImages}
            headingText={intl.formatMessage({ id: 'projects.erythreaB' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.erythreaA.edges[0].node.childImageSharp.fluid}
            projectImages={erythreaAImages}
            headingText={intl.formatMessage({ id: 'projects.erythreaA' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.vrilissiaG.edges[0].node.childImageSharp.fluid}
            projectImages={vrilissiaGImages}
            headingText={intl.formatMessage({ id: 'projects.vrilissiaG' })}
          ></ShowCaseCard>
          <ShowCaseCard
            cardImage={data.chalandriB.edges[0].node.childImageSharp.fluid}
            projectImages={chalandriBImages}
            headingText={intl.formatMessage({ id: 'projects.chalandriB' })}
          ></ShowCaseCard>
        </Flex>

        <StyledLink to="/projects">
          <FormattedMessage id="showcase.button" />
        </StyledLink>
      </SectionContainer>
    </Section>
  );
};

export default Showcase;
